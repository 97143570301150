import { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";

const HomePageSection7 = () => {
  const {t} = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const section7Img = [
    "/Assets/images/homePage/Main/section7/section7-1.jpeg",
    "/Assets/images/homePage/Main/section7/section7-2.jpg",
    "/Assets/images/homePage/Main/section7/section7-3.jpg",
    "/Assets/images/homePage/Main/section7/section7-4.jpg",
    "/Assets/images/homePage/Main/section7/section7-5.jpg",
    "/Assets/images/homePage/Main/section7/section7-6.jpg",
    "/Assets/images/homePage/Main/section7/section7-7.png",
    "/Assets/images/homePage/Main/section7/section7-8.jpg",
    "/Assets/images/homePage/Main/section7/section7-9.jpg",
    "/Assets/images/homePage/Main/section7/section7-10.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === section7Img.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    

    return () => clearInterval(interval);
  }, [section7Img.length]);

  return (
    <>
      <section>
        <div className="section7">
          <div>
            <div className="section7-text">
              <p>
                {t("homePage.section7.text")}: <br/>
                <a className="section7-number" href="tel:+994505736006">(+994) 50 573 60 06</a>
              </p>
            </div>
          </div>
          <div className="section7-slider">
            <img src={section7Img[currentImageIndex]} alt="Slider Foto" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageSection7;
