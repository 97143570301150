import { useNavigate } from "react-router";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useMemo } from "react";

const ServicesPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [padding, setPadding] = useState("");

  const languagePadding = useMemo(() => {
    return {
      az: "0 190px",
      en: "0 210px",
      ru: "0 218px",
      tr: "0 218px",
    };
  }, []);
  
  useEffect(() => {
    const currentLanguage = i18n.language;
    setPadding(languagePadding[currentLanguage] || "0 210px");
  }, [i18n.language,languagePadding]);

  return (
    <>
      <section className="services-description" style={{ padding: padding }}>
        <p>{t("servicesPage.about")}</p>
      </section>
      <section className="services-page">
        <article className="services-box">
          <img
            src="/Assets/images/servicesLayout/servicesPage/services-page1.webp"
            alt=""
          />
        </article>
        <article className="services-about">
          <h1>{t("header-menu.products.products1")}</h1>
          <p>{t("servicesPage.service1Definition")}</p>
          <button onClick={() => navigate("/services/scaffolding")}>
            {t("servicesPage.button")}
          </button>
        </article>
        <article className="services-about">
          <h1>{t("header-menu.products.products2")}</h1>
          <p>{t("servicesPage.service2Definition")}</p>
          <button onClick={() => navigate("/services/formwork")}>
            {t("servicesPage.button")}
          </button>
        </article>
        <article className="services-box">
          <img
            src="/Assets/images/servicesLayout/servicesPage/services-page2.webp"
            alt=""
          />
        </article>
        <article className="services-box">
          <img
            src="/Assets/images/servicesLayout/servicesPage/services-page3.webp"
            alt=""
          />
        </article>
        <article className="services-about">
          <h1>{t("header-menu.products.products3")}</h1>
          <p>{t("servicesPage.service3Definition")}</p>
          <button onClick={() => navigate("/services/bridge-mold")}>
            {t("servicesPage.button")}
          </button>
        </article>
        <article className="services-about">
          <h1>{t("header-menu.products.products4")}</h1>
          <p>{t("servicesPage.service4Definition")}</p>
          <button onClick={() => navigate("/services/curtain-mold")}>
            {t("servicesPage.button")}
          </button>
        </article>
        <article className="services-box">
          <img
            src="/Assets/images/servicesLayout/servicesPage/services-page4.jpg"
            alt=""
          />
        </article>
      </section>
    </>
  );
};

export default ServicesPage;
