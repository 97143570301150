import "./style.css";
import HomePageSection1 from "../HomePageSection1/homePageSection1";
import HomePageSection2 from "../HomePageSection2/homePageSection2";
import HomePageSection2Part2 from "../HomePageSection2Part2/homePageSection2Part2";
import HomePageSection3 from "../HomePageSection3/homePageSection3";
import HomePageSection4 from "../HomePageSection4/homePageSection4";
import HomePageSection4Part2 from "../HomePageSection4Part2/homePageSection4Part2";
import HomePageSection5 from "../HomePageSection5/homePageSection5";
import HomePageSection6 from "../HomePageSection6/homePageSection6";
import HomePageSection7 from "../HomePageSection7/homePageSection7";

const Main = () => {


  return (
    <div className="main" id="homePage">
      <HomePageSection1/>
      <HomePageSection2/>
      <HomePageSection2Part2/>
      <HomePageSection3/>
      <HomePageSection4/>
      <HomePageSection4Part2/>
      <HomePageSection5/>
      <HomePageSection6/>
      <HomePageSection7/>
    </div>
  );
};

export default Main;
