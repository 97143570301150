import "./style.css";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Link as ScrollLink, scroller } from "react-scroll";
import MenuToggle from "../../ui/icons/Menu/MenuToggle";
import { useMenu } from "../../context/isOpenContext";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [productShow, setProductShow] = useState(false);
  const [projectShow, setProjectShow] = useState(false);
  const { isOpen, toggleValue } = useMenu();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(-1);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(true);
  const [currentLang, setCurrentLang] = useState("");

  const supportedLangs = ["en", "az", "ru", "tr"];

  // console.log("dil: " + i18n.language);
  // console.log("isScrolled: " + isScrolled)

  useEffect(() => {
    const baseLang = i18n.language.split("-")[0];
    const langActiveFunc = (baseLang) => {
      switch (baseLang) {
        case "en":
          setCurrentLang("en");
          break;
        case "az":
          setCurrentLang("az");
          break;
        case "ru":
          setCurrentLang("ru");
          break;
        case "tr":
          setCurrentLang("tr");
          break;
        default:
          setCurrentLang("en");
      }
    };
    langActiveFunc(baseLang);
  }, [i18n.language]);

  useEffect(() => {
    const bodyStyle = document.body.style;
    if (isOpen) {
      bodyStyle.overflowY = "hidden";
      bodyStyle.paddingRight = "17px";
    } else {
      bodyStyle.overflowY = "visible";
      bodyStyle.paddingRight = "0px";
    }

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (isOpen) {
        setIsScrolled(3);
      } else if (currentScrollY < 150) {
        setIsScrolled(0);
      } else if (currentScrollY > 150 && currentScrollY > prevScrollY) {
        setIsScrolled(1);
      } else if (currentScrollY < prevScrollY && !isOpen) {
        setIsScrolled(2);
      }

      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY, isScrolled, isOpen]);

  const showHidden = () => {
    toggleValue(false);
    setProductShow(false);
    setProjectShow(false);
  };

  const showClick = (value1, value2) => {
    setProjectShow(value1);
    setProductShow(value2);
  };

  const handleClick = () => {
    setProductShow(false);
    setProjectShow(false);
  };

  const scrollToSection6 = () => {
    navigate("/");
    toggleValue(false);
    setProductShow(false);
    setProjectShow(false);
    setTimeout(() => {
      scroller.scrollTo("section6", {
        duration: 500,
        smooth: true,
      });
      setIsScrolled(0);
    }, 500); // Wait until the animation time of the transition to the Home page
  };

  const clickHandleLanguage = async (lang) => {
    setLang(false);
    await i18n.changeLanguage(lang);
    setTimeout(() => {
      setLang(true);
      if (isScrolled !== 0 && isScrolled !== -1 && !isOpen) setIsScrolled(1);
    }, 100);
  };

  const capitalizeFirstLetter = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <>
      <div className={`dropDownNav ${isOpen ? "active" : ""}`}>
        <div
          className={`header ${
            isScrolled === 1
              ? "header-hidden"
              : isScrolled === 2
              ? "header-show"
              : ""
          } ${isOpen ? "header-background" : ""}`}
        >
          <div>
            <div>
              <MenuToggle handleClick={handleClick} />
            </div>
            <div
              className={`header-logo ${
                isScrolled === 0 || isScrolled === -1 || isScrolled === 1
                  ? "header-logo-background"
                  : ""
              }`}
            >
              <NavLink onClick={showHidden} to="/">
                <img
                  src="/Assets/images/Logo/proform_logo.png"
                  alt="PROFORM MMC Logo"
                />
              </NavLink>
            </div>
            <div className="header-lang-box">
              <ul
                className={`header-active-lang ${
                  isOpen ? "header-active-isOpen" : ""
                }`}
              >
                <li>{currentLang}</li>
              </ul>
              <ul
                className={`header-lang-list ${
                  !lang ? "header-lang-none" : ""
                } ${isOpen ? "header-lang-isOpen" : ""} ${
                  (!isOpen && isScrolled === 2) || isScrolled === 1
                    ? "header-lang-flex"
                    : ""
                }`}
              >
                {supportedLangs.map(
                  (lang, index) =>
                    currentLang !== lang && (
                      <li
                        key={index}
                        className="header-lang"
                        onClick={() => clickHandleLanguage(lang)}
                      >
                        {lang}
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="menu-container">
          <nav className={`menu ${isOpen ? "new-menu" : ""}`}>
            <ul className="menu-left">
              <li>
                <NavLink onClick={showHidden} to="/">
                  {t("header-menu.home")}
                </NavLink>
              </li>
              <li
                onClick={() => showClick(true, false)}
                className={`${projectShow ? "project-line" : ""}`}
              >
                <span>{t("header-menu.projects.projects")}</span>
                <ul
                  className={`menu-right ${
                    projectShow ? "menu-right-project-show" : ""
                  }`}
                >
                  <li>
                    <div>{t("header-menu.projects.projects1")}</div>
                  </li>
                  <li>
                    <div>{t("header-menu.projects.projects2")}</div>
                  </li>
                  <li>
                    <div>{t("header-menu.projects.projects3")}</div>
                  </li>
                  <li className="all-project-line">
                    <NavLink
                      onClick={(e) => {
                        e.stopPropagation();
                        showHidden();
                      }}
                      to="/projects"
                    >
                      {t("header-menu.projects.all")}
                    </NavLink>
                  </li>
                  <li>
                    <img
                      src="/Assets/images/header/navbar_projects_img.jpg"
                      alt="All Projects"
                    />
                  </li>
                  <li>
                    <p>{t("header-menu.projects.definition")}</p>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => showClick(false, true)}
                className={`${productShow ? "project-line" : ""}`}
              >
                <span>{t("header-menu.products.products")}</span>
                <ul
                  className={`menu-right menu-right-product ${
                    productShow ? "menu-right-product-show" : ""
                  }`}
                >
                  <li className="all-project-line">
                    <NavLink
                      onClick={(e) => {
                        e.stopPropagation();
                        showHidden();
                      }}
                      to="/services"
                    >
                      {t("header-menu.products.products")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={(e) => {
                        e.stopPropagation();
                        showHidden();
                      }}
                      to="services/scaffolding"
                    >
                      {t("header-menu.products.products1")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={(e) => {
                        e.stopPropagation();
                        showHidden();
                      }}
                      to="/services/formwork"
                    >
                      {capitalizeFirstLetter(
                        t("header-menu.products.products2")
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={(e) => {
                        e.stopPropagation();
                        showHidden();
                      }}
                      to="/services/bridge-mold"
                    >
                      {capitalizeFirstLetter(
                        t("header-menu.products.products3")
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={(e) => {
                        e.stopPropagation();
                        showHidden();
                      }}
                      to="/services/curtain-mold"
                    >
                      {capitalizeFirstLetter(
                        t("header-menu.products.products4")
                      )}
                    </NavLink>
                  </li>

                  <li>
                    <img
                      src="/Assets/images/header/navbar_projects_img2.png"
                      alt="All Products"
                    />
                  </li>
                  <li>
                    <p>{t("header-menu.products.definition")}</p>
                  </li>
                </ul>
              </li>
              <li>
                <ScrollLink
                  onClick={scrollToSection6}
                  to="section6"
                  smooth={true}
                  duration={500}
                  spy={true}
                >
                  {t("header-menu.e-Catalogue")}
                </ScrollLink>
              </li>
              <li>
                <NavLink onClick={showHidden} to="/about">
                  {t("header-menu.about")}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={showHidden} to="/contact">
                  {t("header-menu.contact")}
                </NavLink>
              </li>
            </ul>
          </nav>
          <nav className={`menu2 ${isOpen ? "new-menu2" : ""}`}>
            {/* <div className="navbar-img">
              <NavLink onClick={showHidden} to="/">
                <img src="/Assets/images/Logo/proform_logo.png" />
              </NavLink>
            </div> */}
            {/* <ul
              className={`menu-right ${
                projectShow ? "menu-right-project-show" : ""
              }`}
            >
              <li>
                <div>Zəfər yolu</div>
              </li>
              <li>
                <div>Toğanalı-Kəlbəcər-İstisu avtomobil yolu</div>
              </li>
              <li>
                <div>Şuşa Butik Otel</div>
              </li>
              <li className="all-project-line">
                <NavLink onClick={showHidden} to="/projects">
                  Bütün Layihələr
                </NavLink>
              </li>
              <li>
                <img
                  src="/Assets/images/header/navbar_projects_img.jpg"
                  alt="All Projects"
                />
              </li>
              <li>
                <p>
                  Tamamlanmış layihələrdən başlayaraq, davam etməkdə olan
                  layihələrimizə diqqət yetirin.
                </p>
              </li>
            </ul> */}

            {/* <ul
              className={`menu-right ${
                productShow ? "menu-right-product-show" : ""
              }`}
            >
              <li className="all-project-line">
                <NavLink onClick={showHidden} to="/services">
                  Xidmətlər
                </NavLink>
              </li>
              <li>
                <NavLink onClick={showHidden} to="services/scaffolding">
                  Tikinti İskələləri
                </NavLink>
              </li>
              <li>
                <NavLink onClick={showHidden} to="/services/formwork">
                  Döşəmə Qəlib
                </NavLink>
              </li>
              <li>
                <NavLink onClick={showHidden} to="/services/bridge-mold">
                  Körpü Qəlib
                </NavLink>
              </li>
              <li>
                <NavLink onClick={showHidden} to="/services/curtain-mold">
                  Pərdə Qəlib
                </NavLink>
              </li>

              <li>
                <img
                  src="/Assets/images/header/navbar_projects_img2.png"
                  alt="All Products"
                />
              </li>
              <li>
                <p>
                  İnşaat məhsulları sahəsində təklif etdiyimiz döşəmə və iskələ
                  sistemləri ilə layihələrinizi möhkəmləndirin.
                </p>
              </li>
            </ul> */}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
