import { useLocation } from "react-router";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

const ServicesElements = () => {
  const articleRef = useRef(null);
  // const [articleHeight, setArticleHeight] = useState(null);
  const { t } = useTranslation();


  const scaffolding = {
    values1: [
      { value: t("servicesElement.scaffolding.category1.title") },
      { value: t("servicesElement.scaffolding.category2.title") },
      { value: t("servicesElement.scaffolding.category3.title") },
      { value: t("servicesElement.scaffolding.category4.title") },
    ],
    values2: {
      product1: [
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.scaffolding.category1.elTitle1"),
          description: t(
            "servicesElement.scaffolding.category1.elDescription1"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-2.png",
          headText: t("servicesElement.scaffolding.category1.elTitle2"),
          description: t(
            "servicesElement.scaffolding.category1.elDescription2"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-3.png",
          headText: t("servicesElement.scaffolding.category1.elTitle3"),
          description: t(
            "servicesElement.scaffolding.category1.elDescription3"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-4.png",
          headText: t("servicesElement.scaffolding.category1.elTitle4"),
          description: t(
            "servicesElement.scaffolding.category1.elDescription4"
          ),
        },
      ],
      product2: [
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-1.png",
          headText: t("servicesElement.scaffolding.category2.elTitle1"),
          description: t(
            "servicesElement.scaffolding.category2.elDescription1"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-2.jpg",
          headText: t("servicesElement.scaffolding.category2.elTitle2"),
          description: t(
            "servicesElement.scaffolding.category2.elDescription2"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-3.png",
          headText: t("servicesElement.scaffolding.category2.elTitle3"),
          description: t(
            "servicesElement.scaffolding.category2.elDescription3"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-4.jpg",
          headText: t("servicesElement.scaffolding.category2.elTitle4"),
          description: t(
            "servicesElement.scaffolding.category2.elDescription4"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-5.png",
          headText: t("servicesElement.scaffolding.category2.elTitle5"),
          description: t(
            "servicesElement.scaffolding.category2.elDescription5"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-6.png",
          headText: t("servicesElement.scaffolding.category2.elTitle6"),
          description: t(
            "servicesElement.scaffolding.category2.elDescription6"
          ),
        },
      ],
      product3: [
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-1.png",
          headText: t("servicesElement.scaffolding.category3.elTitle1"),
          description: t(
            "servicesElement.scaffolding.category3.elDescription1"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-2.jpg",
          headText: t("servicesElement.scaffolding.category3.elTitle2"),
          description: t(
            "servicesElement.scaffolding.category3.elDescription2"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-3.png",
          headText: t("servicesElement.scaffolding.category3.elTitle3"),
          description: t(
            "servicesElement.scaffolding.category3.elDescription3"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-4.jpg",
          headText: t("servicesElement.scaffolding.category3.elTitle4"),
          description: t(
            "servicesElement.scaffolding.category3.elDescription4"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-5.png",
          headText: t("servicesElement.scaffolding.category3.elTitle5"),
          description: t(
            "servicesElement.scaffolding.category3.elDescription5"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-6.png",
          headText: t("servicesElement.scaffolding.category3.elTitle6"),
          description: t(
            "servicesElement.scaffolding.category3.elDescription6"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-6.png",
          headText: t("servicesElement.scaffolding.category3.elTitle7"),
          description: t(
            "servicesElement.scaffolding.category3.elDescription7"
          ),
        },
      ],
      product4: [
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-1.png",
          headText: t("servicesElement.scaffolding.category4.elTitle1"),
          description: t(
            "servicesElement.scaffolding.category4.elDescription1"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-2.jpg",
          headText: t("servicesElement.scaffolding.category4.elTitle2"),
          description: t(
            "servicesElement.scaffolding.category4.elDescription2"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-3.png",
          headText: t("servicesElement.scaffolding.category4.elTitle3"),
          description: t(
            "servicesElement.scaffolding.category4.elDescription3"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-4.jpg",
          headText: t("servicesElement.scaffolding.category4.elTitle4"),
          description: t(
            "servicesElement.scaffolding.category4.elDescription4"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-5.png",
          headText: t("servicesElement.scaffolding.category4.elTitle5"),
          description: t(
            "servicesElement.scaffolding.category4.elDescription5"
          ),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product2/scaffolding2-6.png",
          headText: t("servicesElement.scaffolding.category4.elTitle6"),
          description: t(
            "servicesElement.scaffolding.category4.elDescription6"
          ),
        },
      ],
    },
  };

  const formwork = {
    values1: [{ value: t("servicesElement.formwork.category1.title") }],
    values2: {
      product1: [
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.formwork.category1.elTitle1"),
          description: t("servicesElement.formwork.category1.elDescription1"),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.formwork.category1.elTitle2"),
          description: t("servicesElement.formwork.category1.elDescription2"),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.formwork.category1.elTitle3"),
          description: t("servicesElement.formwork.category1.elDescription3"),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.formwork.category1.elTitle4"),
          description: t("servicesElement.formwork.category1.elDescription4"),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.formwork.category1.elTitle5"),
          description: t("servicesElement.formwork.category1.elDescription5"),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.formwork.category1.elTitle6"),
          description: t("servicesElement.formwork.category1.elDescription6"),
        },
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.formwork.category1.elTitle7"),
          description: t("servicesElement.formwork.category1.elDescription7"),
        },
      ],
    },
  };

  const bridgeMold = {
    values1: [{ value: t("servicesElement.bridgeMold.category1.title") }],
    values2: {
      product1: [
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.bridgeMold.category1.elTitle1"),
          description: t("servicesElement.bridgeMold.category1.elDescription1"),
        },
      ],
    },
  };

  const curtainMold = {
    values1: [{ value: t("servicesElement.curtainMold.category1.title") }],
    values2: {
      product1: [
        {
          imgSrc:
            "/Assets/images/servicesElement/scaffolding/product1/scaffolding1-1.jpg",
          headText: t("servicesElement.curtainMold.category1.elTitle1"),
          description: t(
            "servicesElement.curtainMold.category1.elDescription1"
          ),
        },
      ],
    },
  };

  // useEffect(() => {
  //   if (articleRef.current) {
  //     const height = articleRef.current.clientHeight;
  //     setArticleHeight(height);
  //   }
  // }, []);

  const { pathname } = useLocation();

  const getPageValue = (pathname) => {
    switch (pathname) {
      case "/services/scaffolding":
        return scaffolding;
      case "/services/formwork":
        return formwork;
      case "/services/bridge-mold":
        return bridgeMold;
      case "/services/curtain-mold":
        return curtainMold;
      default:
        return null;
    }
  };

  const pageValue = getPageValue(pathname);

  const [element, setElement] = useState("product1");

  const productActive = (e) => {
    const { name } = e.target;
    setElement(name);
  };


  return (
    <>
      <section className="services-product-box">
        {pageValue.values1.map((el, index) => (
          <input
            key={`navbar-${index}`}
            type="button"
            name={"product" + (index + 1)}
            onClick={productActive}
            className={`services-product ${
              element === "product" + (index + 1) ? "productActive" : ""
            }`}
            value={el.value}
          />
        ))}
      </section>
      <section className="services-product-sect1">
        {pageValue.values2[element].map((el, index) => (
          <div key={`element-${index}`}>
            <article
              className="services-product-img"
              // style={{ height: articleHeight + 95 + "px" }}
            >
              <img src={el.imgSrc} alt={el.headText} />
            </article>
            <article className="services-product-text" ref={articleRef}>
              <h1>{el.headText}</h1>
              <p>{el.description}</p>
            </article>
          </div>
        ))}
      </section>
    </>
  );
};

export default ServicesElements;

// const ServicesElements = () => {
//   const articleRefs = useRef([]);
//   const [articleHeights, setArticleHeights] = useState([]);

//   useEffect(() => {
//     // Hesaplama işlemini burada yapabilirsiniz
//     const heights = articleRefs.current.map((ref) => ref.clientHeight);
//     setArticleHeights(heights);
//   }, []);

//   const { pathname } = useLocation();

//   const getPageValue = (pathname) => {
//     // ...

//     return pageValue;
//   };

//   const pageValue = getPageValue(pathname);

//   const [element, setElement] = useState("product1");

//   const productActive = (e) => {
//     const { name } = e.target;
//     setElement(name);
//   };

//   return (
//     <>
//       <section className="services-product-box">
//         {pageValue.values1.map((el, index) => (
//           <input
//             key={`navbar-${index}`}
//             type="button"
//             name={"product" + (index + 1)}
//             onClick={productActive}
//             className={`services-product ${
//               element === "product" + (index + 1) ? "productActive" : ""
//             }`}
//             value={el.value}
//           />
//         ))}
//       </section>
//       <section className="services-product-sect1">
//         {pageValue.values2[element].map((el, index) => (
//           <div key={`element-${index}`}>
//             <article
//               className="services-product-img"
//               style={{ height: articleHeights[index] + 95 + "px" }}
//               ref={(ref) => (articleRefs.current[index] = ref)}
//             >
//               <img src={el.imgSrc} alt={el.headText} />
//             </article>
//             <article className="services-product-text">
//               <h1>{el.headText}</h1>
//               <p>{el.description}</p>
//             </article>
//           </div>
//         ))}
//       </section>
//     </>
//   );
// };

// export default ServicesElements;
