import { useTranslation } from "react-i18next";
import PageHeader from "../../Layouts/PageHeader";
import "./style.css";

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader
        headerText={t("header-menu.about")}
        imgSrc="/Assets/images/about/about_page1.jpg"
        imgAlt="About Page"
      />
      <section>
        <div className="about-sect1">
          <div>
            <div className="waviy">
              <span style={{ "--i": 1 }}>P</span>
              <span style={{ "--i": 2 }}>R</span>
              <span style={{ "--i": 3 }}>O</span>
              <span style={{ "--i": 4 }}>F</span>
              <span style={{ "--i": 5 }}>O</span>
              <span style={{ "--i": 6 }}>R</span>
              <span style={{ "--i": 7 }}>M</span>
              <span style={{ "--i": 8, marginLeft: "30px" }}></span>
              <span style={{ "--i": 9 }}>M</span>
              <span style={{ "--i": 10 }}>M</span>
              <span style={{ "--i": 11 }}>C</span>
            </div>
            <p className="about-sect1-p1">{t("about.section1.text1")}</p>
            <p>{t("about.section1.text2")}</p>
            <p>{t("about.section1.text3")}</p>
          </div>
        </div>
      </section>
      <section>
        <div className="about-sect2">
          <div></div>
        </div>
      </section>
      <section>
        <div className="about-sect3">
          <div className="about-sect3-upper">
            <div>
              <img
                className="about-sect3-img"
                src="/Assets/images/about/about_page3-1.jpg"
                alt="About Page"
              />
            </div>
            <div>
              <div className="about-sect3-text">
                <h1>{t("about.section3.headText1")}</h1>
                <div className="about-sect3-line"></div>
                <p>{t("about.section3.text1")}</p>
              </div>
            </div>
          </div>
          <div className="about-sect3-down">
            <div>
              <div className="about-sect3-text">
                <h1>{t("about.section3.headText2")}</h1>
                <div className="about-sect3-line"></div>
                <p>{t("about.section3.text2")}</p>
              </div>
            </div>
            <div>
              <img
                className="about-sect3-img"
                src="/Assets/images/about/about_page3-2.jpg"
                alt="About Page"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="about-sect4">
          <div>
            <article className="about-sect4-box">
              <div className="about-sect4-headText">
                <h1>{t("about.section4.vision")}</h1>
              </div>
              <div className="about-sect4-text">
                <p>{t("about.section4.visionDefinition")}</p>
              </div>
            </article>
            <article className="about-sect4-box">
              <div className="about-sect4-headText">
                <h1>{t("about.section4.mission")}</h1>
              </div>
              <div className="about-sect4-text">
                <p>{t("about.section4.missionDefinition")}</p>
              </div>
            </article>
            <article className="about-sect4-box">
              <div className="about-sect4-headText">
                <h1>{t("about.section4.value.value")}</h1>
              </div>
              <div className="about-sect4-text">
                <p>{t("about.section4.value.definition")}:</p>
                <div>
                  <ul>
                    <li>{t("about.section4.value.safety")}</li>
                    <li>{t("about.section4.value.quality")}</li>
                    <li>{t("about.section4.value.environmentalProtection")}</li>
                    <li>{t("about.section4.value.humanDevelopment")}</li>
                  </ul>
                  <ul>
                    <li>{t("about.section4.value.innovation")}</li>
                    <li>{t("about.section4.value.honesty")}</li>
                    <li>{t("about.section4.value.sustainability")}</li>
                    <li>{t("about.section4.value.cooperation")}</li>
                  </ul>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
      <section>
        <div className="about-sect5">
          <div></div>
        </div>
      </section>
    </>
  );
};

export default About;
