import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

const HomePageSection6 = () => {
  const {t}=useTranslation();
  const [internetActive, setInternetActive] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setInternetActive(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  return (
    <>
      <section>
        <Element name="section6" className="section6">
          <div className="head-text">
            <h1>{t("header-menu.e-Catalogue")}</h1>
            <div className="section6-line"></div>
          </div>
          <div>
            {internetActive ? (
              <iframe
                className="e-catalog fbo-embed"
                src="https://online.flippingbook.com/view/891173259/"
                title="E-Catalog"
                allowFullScreen
                data-fbo-id="f2d0976ee6" data-fbo-ratio="3:2" data-fbo-lightbox="yes" data-fbo-width="100%" data-fbo-height="auto" data-fbo-version="1" style={{ maxWidth: '100%' }}
              ></iframe>
            ) : (
              <a
                href="https://f7419596-9fa4-4998-8620-9a1adbcfea0c.usrfiles.com/ugd/f74195_f9c82c8a63094d20b75363f22ed0d17a.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/Assets/images/homePage/Main/section6/E-catalog-image.jpg"
                  alt="E-Catalog"
                />
              </a>
            )}
          </div>
          <div></div>
        </Element>
      </section>
    </>
  );
};

export default HomePageSection6;
