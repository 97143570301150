import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import HomePage from "../Pages/HomePage";
import About from "../Pages/About/about";
import Contact from "../Pages/Contact";
import ScrollToTop from "./scrollToTop";
import BackToTopButton from "./BackToTopButton/backToTopButton";
import Projects from "../Pages/Projects";
import ServicesPageLayouts from "../Pages/ServicesPage/ServicesPageLayouts";
import ServicesPage from "../Pages/ServicesPage/ServicesPage";
import ServicesElements from "../Pages/ServicesPage/ServicesElements";
import ToggleComponent from "../test/testPage/ToggleComponent";

const Router = () => {
  const Layout = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
        <BackToTopButton />
      </>
    );
  };

  const BrowserRoutes = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Layout />
          <ScrollToTop />
        </>
      ),
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/projects",
          element: <Projects />,
        },
        {
          path: "/test",
          element: <ToggleComponent/>,
        },
        {
          path: "/services",
          element: <ServicesPageLayouts />,
          children: [
            {
              path:"/services",
              element: <ServicesPage/>
            }
            ,
            {
              path:"/services/scaffolding",
              element: <ServicesElements/>
            },
            {
              path:"/services/formwork",
              element: <ServicesElements/>
            },
            {
              path:"/services/bridge-mold",
              element: <ServicesElements/>
            },
            {
              path:"/services/curtain-mold",
              element: <ServicesElements/>
            },
          ]
        }
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={BrowserRoutes} />
    </>
  );
};

export default Router;
