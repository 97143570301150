// import logo from './logo.svg';
import "./App.css";
import { motion, useScroll } from "framer-motion";
import Router from "./components/router";
import PageProvider from "./context/isOpenContext"
import React, { useState, useEffect } from 'react';
import Loading from "./components/Loading/loading";


function App() {
  const { scrollYProgress } = useScroll();
  const [loading, setLoading] = useState(true);
  
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer); 
  }, []);

  return (
    <PageProvider>
      {loading ? (
        <Loading />
      ) : (
        <>
          <motion.div
            className="progress-bar"
            style={{ scaleX: scrollYProgress }}
          />
          <Router /> 
        </>
      )}
    </PageProvider>
  );
}

export default App;
