import "./styles.css";
import { useEffect } from "react";
import { useAnimate } from "framer-motion";
import { MenuIcon } from "./icon";
import { useMenu } from "../../../context/isOpenContext";

function useMenuAnimation(isOpen) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const menuAnimations = isOpen
      ? [
          [
            "nav",
            { transform: "translateX(0%)" },
            { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.6 },
          ],
        ]
      : [["nav", { transform: "translateX(-100%)" }, { at: "-0.1" }]];

    animate([
      [
        "path.top",
        { d: isOpen ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5" },
        { at: "<" },
      ],
      ["path.middle", { opacity: isOpen ? 0 : 1 }, { at: "<" }],
      [
        "path.bottom",
        { d: isOpen ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346" },
        { at: "<" },
      ],
      ...menuAnimations,
    ]);
  }, [isOpen, animate]);

  return scope;
}

export default function MenuToggle({ handleClick }) {

  const { isOpen, toggleValue }= useMenu()
  const scope = useMenuAnimation(isOpen);

  return (
    <div ref={scope}>
      <MenuIcon
        toggle={toggleValue}
        isOpen={isOpen}
        handleClick={handleClick}
      />
    </div>
  );
}
