import { Link } from "react-router-dom";
import { VscNewline } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

const HomePageSection2Part2 = () => {
  const { t } = useTranslation();
  return (
    <>
      <section>
        <div className="section2-2">
          <div className="head-text">
            <h1>{t("header-menu.projects.projects")}</h1>
          </div>
          <div>
            <div className="card-container">
              <div className="card card1">
                <div className="img">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="content">
                  <h2>Proform MMC</h2>
                  <h4>
                    {t("homePage.section2-2.about")}
                  </h4>
                  <div>
                    <div >
                      <Link
                        className="homePage-projects"
                        to="/projects"
                      >
                        <span>{t("header-menu.projects.all")}</span>
                        <VscNewline className="content-icon" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="card card2">
                <div className="img">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="content">
                  <h2>Proform MMC</h2>
                  <h2>{t("header-menu.projects.projects1")}</h2>
                </div>
              </div>
              <div className="card card3">
                <div className="img">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="content">
                  <h2>Proform MMC</h2>
                  <h2>{t("homePage.section2-2.project")}</h2>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="card card4">
                <div className="img">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="content">
                  <h2>Proform MMC</h2>
                  <h2>{t("header-menu.projects.projects2")}</h2>
                </div>
              </div>
              <div className="card card5">
                <div className="img">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="content">
                  <h2>Proform MMC</h2>
                  <h2>{t("header-menu.projects.projects3")}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageSection2Part2;
