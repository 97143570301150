// src/components/Loading.js
import React from "react";
import "./loading.css";

const Loading = () => {
  return (
    <div id="loading-div">
      <span id="loading" className="loader"></span>
    </div>
  );
};

export default Loading;
