import { useTranslation } from "react-i18next";

const HomePageSection5 = () => {
  const {t} = useTranslation()

  const partners = [
    {
      imgSrc:
        "/Assets/images/homePage/Main/Section5/Azerbaycan_Demir_Yollari.jpg",
      alt: "Azərbaycan Dəmir Yolları",
    },
    {
      imgSrc: "/Assets/images/homePage/Main/Section5/AAY_Dövlət_Agentliyi.jpg",
      alt: "AAY_Dövlət_Agentliyi",
    },
    {
      imgSrc: "/Assets/images/homePage/Main/Section5/Polatyol.png",
      alt: "Polatyol",
    },
    {
      imgSrc: "/Assets/images/homePage/Main/Section5/Kapital bank.png",
      alt: "Kapital Bank",
    },
    {
      imgSrc: "/Assets/images/homePage/Main/Section5/NN_Constroction.png",
      alt: "NN Construction",
    },
    {
      imgSrc: "/Assets/images/homePage/Main/Section5/Azeraqrar.png",
      alt: "Azəraqrar Tikinti ASC",
    },
    {
      imgSrc: "/Assets/images/homePage/Main/Section5/Peri.png",
      alt: "Peri",
    },
    {
      imgSrc: "/Assets/images/homePage/Main/Section5/texnonikol.png",
      alt: "Texnonikol",
    },
    {
      imgSrc: "/Assets/images/homePage/Main/Section5/6cimertebe.png",
      alt: "6cı Mərtəbə",
    },

    {
      imgSrc: "/Assets/images/homePage/Main/Section5/Portisan.png",
      alt: "Partisan",
    },
  ];
  
  return (
    <>
       <section>
        <div className="section5">
          <div className="head-text">
            <h1>{t("homePage.section5.headText")}</h1>
            <div></div>
          </div>
          <div className="partners-img">
            {partners.map((partner, index) => (
              <div key={index}>
                <img src={partner.imgSrc} alt={partner.alt} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageSection5;
