import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

const PageHeader = (props) => {
  const { headerText, imgSrc, imgAlt, videoSrc, navigateText } = props;
  const { t } = useTranslation();
  return (
    <>
      <header className="page-header">
        <div className="banner-overlay"></div>
        <div className="banner-overlay banner-overlay-dark"></div>
        {imgSrc ? (
          <img src={imgSrc} alt={imgAlt} />
        ) : (
          <video loop={true} muted={true} autoPlay={true} playsInline={true}>
            <source src={videoSrc} type="video/mp4" />
          </video>
        )}
        <div className="page-headText">
          <div>
            <h1>{headerText}</h1>
            <nav>
              <ul>
                <li>
                  <Link to="/">
                    {t("header-menu.home")} {">"}
                  </Link>
                </li>
                {navigateText ? (
                  <>
                    <li className="navigateText">
                      <Link to="/services">
                        {headerText} {navigateText ? ">" : ""}
                      </Link>
                    </li>
                    <li>{navigateText}</li>
                  </>
                ) : (
                  <li>{headerText}</li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default PageHeader;
