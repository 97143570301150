import { createContext, useContext, useState } from "react";

const Context = createContext();

const Provider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleValue(value) {
    setIsOpen(currentValue =>
      typeof value === "boolean" ? value : !currentValue
    );
  }

  const menuData = {
    isOpen,
    toggleValue,
  };

  return <Context.Provider value={menuData}>{children}</Context.Provider>;
};

export const useMenu = () => useContext(Context);

export default Provider;
