import PageHeader from "../../Layouts/PageHeader";
import ImageGallery from "react-image-gallery";
import { useTranslation } from "react-i18next";
import "./style.css";

const Projects = () => {
  const { t } = useTranslation();

  const proformProjects = [
    {
      imgSrc: "/Assets/images/homePage/section2-card2.jpg",
      alt: t("projects.project1"),
      h2: t("projects.project1"),
    },
    {
      imgSrc: "/Assets/images/homePage/section2-card3.jpg",
      alt: t("projects.project2"),
      h2: t("projects.project2"),
    },
    {
      imgSrc: "/Assets/images/homePage/section2-card4.jpg",
      alt: t("projects.project3"),
      h2: t("projects.project3"),
    },
    {
      imgSrc: "/Assets/images/homePage/section2-card5.jpg",
      alt: t("projects.projecs4"),
      h2: t("projects.project4"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project5"),
      h2: t("projects.project5"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project6"),
      h2: t("projects.project6"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project7"),
      h2: t("projects.project7"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project8"),
      h2: t("projects.project8"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project9"),
      h2: t("projects.project9"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project10"),
      h2: t("projects.project10"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project11"),
      h2: t("projects.project11"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project12"),
      h2: t("projects.project12"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project13"),
      h2: t("projects.project13"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project14"),
      h2: t("projects.project14"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project15"),
      h2: t("projects.project15"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project16"),
      h2: t("projects.project16"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project17"),
      h2: t("projects.project17"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project18"),
      h2: t("projects.project18"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project19"),
      h2: t("projects.project19"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project20"),
      h2: t("projects.project20"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project21"),
      h2: t("projects.project21"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project22"),
      h2: t("projects.project22"),
    },
    {
      imgSrc: "/Assets/images/homePage/section3-2.jpg",
      alt: t("projects.project23"),
      h2: t("projects.project23"),
    },
  ];

  const images = [
    {
      original: "/Assets/images/projects/orijinal/galery1.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage1.webp",
    },
    {
      original: "/Assets/images/projects/orijinal/galery2.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage2.webp",
    },
    {
      original: "/Assets/images/projects/orijinal/galery3.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage3.webp",
    },
    {
      original: "/Assets/images/projects/orijinal/galery4.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage4.webp",
    },
    {
      original: "/Assets/images/projects/orijinal/galery5.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage5.webp",
    },
    {
      original: "/Assets/images/projects/orijinal/galery6.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage6.jpg",
    },
    {
      original: "/Assets/images/projects/orijinal/galery7.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage7.webp",
    },
    {
      original: "/Assets/images/projects/orijinal/galery8.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage8.webp",
    },
    {
      original: "/Assets/images/projects/orijinal/galery9.webp",
      thumbnail: "/Assets/images/projects/thumbnal/thumbnaImage9.jpg",
    },
  ];

  return (
    <>
      <PageHeader
        headerText={t("header-menu.projects.projects")}
        imgSrc="/Assets/images/projects/projects_page1.jpg"
        imgAlt="Project Page"
      />
      <main className="projects-main">
        <div className="projects-container">
          <section className="projects-sect1">
            <p>
              <b>"PROFORM" MMC {t("footer.about")}</b>
            </p>
          </section>
          <section className="projects-sect2">
            {proformProjects?.map((project, index) => (
              <article key={index} className="projects-sect2-box">
                <div>
                  <figure>
                    <img src={project.imgSrc} alt={project.alt} />
                  </figure>
                </div>
                <div>
                  <h2 className="project-name">{project.h2}</h2>
                </div>
              </article>
            ))}
          </section>
        </div>
      </main>
      <section className="projects-galery">
        <div>
          <ImageGallery
            items={images}
            slideInterval={3000}
            // slideOnThumbnailOver={true}
            // showIndex={true}
          />
        </div>
      </section>
    </>
  );
};

export default Projects;
