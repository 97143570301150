import { useLayoutEffect , useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

    useEffect(()=>{
       if(pathname === "/"){ 
         window.scrollTo(0,0)
       }
    })

  return null;
};

export default ScrollToTop;
