import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./style.css";
import PageHeader from "../../Layouts/PageHeader";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();
  let hasInternetConnection = navigator.onLine;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7wk59m2",
        "template_3ryor1r",
        form.current,
        "69GMK08fQqYJweu-n"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message text");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <PageHeader
        headerText={t("header-menu.contact")}
        imgSrc="/Assets/images/contact/contact_page1.jpg"
        imgAlt="Contact Page"
      />
      <section>
        <div className="contact-sect1">
          <div>
            <form ref={form} onSubmit={sendEmail}>
              <div>
                <div>
                  <input
                    className="contact-input contact-input-left"
                    type="text"
                    name="user_name"
                    placeholder={t("contact.section1.userName")}
                    required
                  />
                  <input
                    className="contact-input"
                    type="email"
                    name="user_email"
                    placeholder={t("contact.section1.userEmail")}
                  />
                </div>
                <div>
                  <input
                    className="contact-input contact-input-left"
                    type="tel"
                    name="user_phone"
                    placeholder={t("contact.section1.userPhone")}
                  />
                  <input
                    className="contact-input"
                    type="text"
                    name="user_address"
                    placeholder={t("contact.section1.userAddress")}
                  />
                </div>
                <div>
                  <input
                    className="contact-input contact-input1"
                    type="text"
                    name="user_subject"
                    placeholder={t("contact.section1.userSubject")}
                  />
                </div>
                <div>
                  <textarea
                    className="contact-input contact-input2"
                    name="message"
                    placeholder={t("contact.section1.userMessage")}
                  />
                </div>
                <div>
                  <input
                    className="contact-submit"
                    type="submit"
                    value={t("contact.section1.button")}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section>
        <div className="contact-sect2-map">
          <div>
            {hasInternetConnection ? (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1833.0699274839258!2d49.81696312405226!3d40.3945550683746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4030877e24d66dab%3A0x690b5b8152acbaaf!2s49b%20Tbilisi%20prospekti%2C%20Bak%C4%B1%201065!5e0!3m2!1saz!2saz!4v1692689784665!5m2!1saz!2saz"
                width="930"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Xəritə"
              />
            ) : (
              <a
                href="https://www.google.com/maps?ll=40.39475,49.817258&z=17&t=m&hl=az&gl=AZ&mapclient=embed&q=49b+Tbilisi+prospekti+Bak%C4%B1+1065"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/Assets/images/contact/proform-map.jpg"
                  alt="Proform MMC Map"
                />
              </a>
            )}
          </div>
        </div>
      </section>
      <section>
        <div className="contact-sect3">
          <div>
            <div>
              <div className="contact-address">
                <div>
                  <h1>{t("contact.section3.addressHeadText")}</h1>
                  <p>
                    {t("contact.section3.address")}
                  </p>
                </div>
              </div>
              <div className="contact-phone">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 65 65"
                    fill="none"
                  >
                    <rect width="65" height="65" fill="rgb(3, 1, 76)" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M47.6989 37.7565L52.9203 42.9777C54.3599 44.4172 54.3599 46.7594 52.9203 48.1989L51.0216 50.0975C45.8178 55.301 37.3961 55.3006 32.1928 50.0975L15.8932 33.79C10.7022 28.5993 10.7022 20.1531 15.8934 14.9622L17.796 13.0763C19.2317 11.6406 21.574 11.6406 23.0135 13.08L28.2336 18.2834C29.6882 19.7379 29.6682 22.0729 28.235 23.506L25.8616 25.8792C24.7683 26.9724 24.7685 28.7416 25.8616 29.8347L36.1488 40.1302C37.239 41.22 39.0135 41.2203 40.1041 40.1298L42.4775 37.7565C43.9169 36.3171 46.2594 36.3171 47.6989 37.7565ZM21.2745 14.8217C20.7931 14.3405 20.0125 14.3406 19.5326 14.8204L18.6668 15.6786L25.6242 22.6357L26.4945 21.7655C26.9755 21.2845 26.9755 20.5061 26.4945 20.0251L21.2745 14.8217ZM33.9336 48.3573L17.6339 32.0497C13.6176 28.0336 13.3968 21.7274 16.9549 17.4476L23.8921 24.3845C22.0693 26.4508 22.1453 29.5994 24.1208 31.5748L34.4076 41.8698L34.4079 41.8701C36.3813 43.8435 39.5299 43.9244 41.5988 42.0992L48.5361 49.0362C44.2698 52.588 37.9775 52.4011 33.9336 48.3573ZM50.3097 47.3288L51.18 46.4585C51.6598 45.9788 51.6598 45.198 51.1799 44.7181L45.9585 39.4969C45.4785 39.017 44.6978 39.0171 44.2179 39.4969L43.3478 40.3671L50.3097 47.3288Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>
                  <h1>{t("contact.section3.phoneHeadText")}</h1>
                  <p>+994 50 493 32 01</p>
                  <p>+994 50 573 60 06</p>
                </div>
              </div>
              <div className="contact-gmail">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 65 65"
                    fill="none"
                  >
                    <rect width="65" height="65" fill="rgb(31,161,215)" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 31.6875C10 20.92 19.8969 12 32.4121 12C44.9551 12 55 20.9401 55 31.6875C55 42.4288 44.962 51.375 32.4121 51.375C29.5045 51.375 26.6874 50.8964 24.0251 49.9513C21.333 52.5344 17.7119 54 13.9551 54C12.7813 54 12.196 52.5826 13.0229 51.7594C15.0007 49.7904 15.7978 46.9345 15.1413 44.2393C11.8231 40.7184 10 36.2744 10 31.6875ZM24.1713 47.2011C26.7636 48.2289 29.5362 48.7675 32.4121 48.7675C43.4132 48.7675 52.3809 41.0958 52.3809 31.6875C52.3809 22.2792 43.4132 14.6075 32.4121 14.6075C21.508 14.6075 12.6191 22.2792 12.6191 31.6875C12.6191 35.6982 14.284 39.5962 17.275 42.6633C17.4268 42.819 17.5376 43.0097 17.5972 43.2185C18.3535 45.8657 18.0359 48.6598 16.7935 51.0301C19.0484 50.4748 21.1185 49.2668 22.7109 47.5344C23.0808 47.1319 23.6622 46.9993 24.1713 47.2011Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 32C18 29.7944 19.7944 28 22 28C24.2056 28 26 29.7944 26 32C26 34.2056 24.2056 36 22 36C19.7944 36 18 34.2056 18 32ZM20.6667 32C20.6667 32.7352 21.2648 33.3333 22 33.3333C22.7352 33.3333 23.3333 32.7352 23.3333 32C23.3333 31.2648 22.7352 30.6667 22 30.6667C21.2648 30.6667 20.6667 31.2648 20.6667 32Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M28 32C28 29.7944 29.7944 28 32 28C34.2056 28 36 29.7944 36 32C36 34.2056 34.2056 36 32 36C29.7944 36 28 34.2056 28 32ZM30.6667 32C30.6667 32.7352 31.2648 33.3333 32 33.3333C32.7352 33.3333 33.3333 32.7352 33.3333 32C33.3333 31.2648 32.7352 30.6667 32 30.6667C31.2648 30.6667 30.6667 31.2648 30.6667 32Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M39 32C39 29.7944 40.7944 28 43 28C45.2056 28 47 29.7944 47 32C47 34.2056 45.2056 36 43 36C40.7944 36 39 34.2056 39 32ZM41.6667 32C41.6667 32.7352 42.2648 33.3333 43 33.3333C43.7352 33.3333 44.3333 32.7352 44.3333 32C44.3333 31.2648 43.7352 30.6667 43 30.6667C42.2648 30.6667 41.6667 31.2648 41.6667 32Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>
                  <h1>{t("contact.section3.gmailHeadText")}</h1>
                  <p>info@proform.az</p>
                  <p>office@proform.az</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
