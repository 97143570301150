import { useTranslation } from "react-i18next";

const HomePageSection2 = () => {
  const { t } = useTranslation();
  return (
    <>
      <section>
        <div className="section2">
          <div>
            <div className="section2-text">
              <p>
                <span>{t("homePage.section2.about1")}, </span>
                {t("homePage.section2.about2")}
              </p>
            </div>
          </div>
          <div>
            <img src="/Assets/images/homePage/section2.png" alt="Proform MMC" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageSection2;
