import useToggle from "../useToggle";

const ToggleComponent = () => {
  const [value, toggleValue] = useToggle(false);
  return (
    <>
      <h1>Test Page</h1>
      <h1>Test Page</h1>
      <h1>Test Page</h1>
      <h1>Test Page</h1>
      <h1>Test Page</h1>
      <h1>Test Page</h1>
      <h1>Test Page</h1>


      <div>
        <div>{value.toString()}</div>
        <button onClick={toggleValue}>Toggle</button>
        <button onClick={()=>toggleValue(true)}>Make True</button>
        <button onClick={()=>toggleValue(false)}>Make False</button>
      </div>
    </>
  );
};

export default ToggleComponent;
