import { useTranslation } from "react-i18next";

const HomePageSection1 = () => {
  const { t } = useTranslation();

  
  return (
    <>
      <section>
        <div className="section1">
          <video loop={true} muted={true} autoPlay={true} playsInline={true}>
            <source
              src="/Assets/video/FORMWORK _SCAFFOLDING SYSTEMS _PROFORM_MMC_HomePage.mp4"
              type="video/mp4"
            />
          </video>
          <div className="section1-background"></div>
          <div className="section1-headText">{t("homePage.section1.definition")}</div>
        </div>
      </section>
    </>
  );
};

export default HomePageSection1;
