import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VscNewline } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";

const HomePageSection4 = () => {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  

  // const originalText = "İnşaat İskelesİ";
  // const transformedText = capitalizeFirstLetter(originalText);

  // console.log(transformedText);

  const products = [
    {
      label: t("homePage.section4.products1"),
      imgSrc: "/Assets/images/homePage/Main/section4-products/iskele.png",
      navigator: "/services/scaffolding",
    },
    {
      label: t("header-menu.products.products2"),
      imgSrc: "Assets/images/homePage/Main/section4-products/Doseme.png",
      navigator: "/services/formwork",
    },
    {
      label: t("header-menu.products.products3"),
      imgSrc: "/Assets/images/homePage/Main/section4-products/korpu_qelibi.png",
      navigator: "services/bridge-mold",
    },
    {
      label: t("header-menu.products.products4"),
      imgSrc: "/Assets/images/homePage/Main/section4-products/Perde_qelibi.png",
      navigator: "services/curtain-mold",
    },
  ];

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <>
      <section>
        <div className="section4">
          <div className="head-text">
            <Link to="/services">
              <h1 className="section4-headText">{t("header-menu.products.products")}</h1>
            </Link>
          </div>
          <div className="section4-products">
            <div>
              <div
                className={`section4-text ${
                  hoveredIndex !== null ? `hovered${hoveredIndex + 1}` : ""
                }`}
              >
                {products.map((product, index) => (
                  <div className="section4-text-box" key={`text-${index}`}>
                    <div
                      onMouseEnter={() => handleHover(index)}
                      onMouseLeave={handleMouseLeave}
                      className="product-div"
                    >
                      <div className={`products-link${index + 1}`}>
                        {product.label}
                      </div>
                    </div>
                    <div className="section4-button">
                      <button onClick={() => handleClick(product.navigator)}>
                        <span>{t("homePage.section4.button")}</span>
                        <VscNewline className="section4-icon" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div
                className={`section4-gallery ${
                  hoveredIndex !== null ? `hovered${hoveredIndex + 1}` : ""
                }`}
              >
                {products.map((product, index) => (
                  <img
                    key={`image-${index}`}
                    src={product.imgSrc}
                    alt={product.label}
                    className={`products-img${index + 1}`}
                    onMouseEnter={() => handleHover(index)}
                    onMouseLeave={handleMouseLeave}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageSection4;
