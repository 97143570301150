import { useTranslation } from "react-i18next";

const HomePageSection4Part2 = () => {
  const { t } = useTranslation();
  return (
    <>
      <section>
        <div className="section4-2">
          <div>
            <img
              src="/Assets/images/homePage/section4-2.png"
              alt="Proform.az"
            />
            <div className="section4-2-circular">
              <p>Proform MMC</p>
            </div>
          </div>
          <div>
            <div className="section4-2_2">
              <p>
                {t("homePage.section4-2.definition")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageSection4Part2;
