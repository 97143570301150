import { animateScroll as scroll } from "react-scroll";
import { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./backToTopButton.css";
import { useMenu } from "../../context/isOpenContext";

const BackToTopButton = () => {
  const { show } = useMenu();
  const [showButton, setShowButton] = useState(false);

  const onScroll = () => {
    window.scrollY > 700 ? setShowButton(true) : setShowButton(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  const handleGoUpClick = () => {
    scroll.scrollToTop();
  };

  return (
    <FaArrowUp
      style={show ? {opacity:0} : {}}
      onClick={handleGoUpClick}
      className={showButton ? "upButtonShow" : "upButtonHidden"}
    />
  );
};

export default BackToTopButton;
