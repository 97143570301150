import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-background"></div>
      <div className="footer-container">
        <div className="footer-left">
          <div>
            <Link to="/">
              <img
                src="/Assets/images/Logo/proform_logo.png"
                alt="Footer Logo"
              />
            </Link>
          </div>
          <div>© 2023 Proform MMC.</div>
          <div>
            <span>PROFORM MMC</span>
            {t("footer.about")}
          </div>
        </div>
        <div className="footer-right">
          <div>
            <ul>
              <li>{t("footer.location")}</li>
              <li>
                <a href="tel:+994504933201">(+994) 50 493 32 01</a>
              </li>
              <li>
                <a href="tel:+994505736006">(+994) 50 573 60 06</a>
              </li>
              <li>
                <a href="tel:+994502623313">(+994) 50 262 33 13</a>
              </li>
              <li>
                <a href="mailto:info@proform.az">info@proform.az</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
