import { Outlet, useLocation } from "react-router";
import PageHeader from "../../../Layouts/PageHeader";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

const ServicesPageLayouts = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [pageTitle, setPageTitle] = useState("");

  const navigateTextFunc = useCallback((pathname) => {
    switch (pathname) {
      case "/services/scaffolding":
        setPageTitle(t("header-menu.products.products1"));
        break;
      case "/services/formwork":
        setPageTitle(t("header-menu.products.products2"));
        break;
      case "/services/bridge-mold":
        setPageTitle(t("header-menu.products.products3"));
        break;
      case "/services/curtain-mold":
        setPageTitle(t("header-menu.products.products4"));
        break;
      default:
    }
  }, [t]);
  
  useEffect(() => {
    navigateTextFunc(pathname);
  }, [pathname, navigateTextFunc]);

  return (
    <>
      <PageHeader
        videoSrc="/Assets/video/Proform_Services.mp4"
        headerText={t("header-menu.products.products")}
        navigateText={pageTitle}
      />
      <main className="services-main">
        <div className="services-container">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default ServicesPageLayouts;
