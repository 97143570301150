import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";

const HomePageSection3 = () => {
  const { t } = useTranslation();
  return (
    <>
      <m.section
        initial={{ y: "20%" }}
        animate={{ y: "0" }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        exit={{ opacity: 1 }}
      >
        <div className="section3">
          <m.div
            initial={{ x: "100%" }}
            animate={{ x: "0" }}
            transition={{ duration: 2, ease: "easeInOut" }}
            exit={{ opacity: 1 }}
          >
            <div className="section3-upper">
              <img
                src="/Assets/images/homePage/section3-1.png"
                alt="Proform.az"
              />
              <div className="circular-box1"></div>
            </div>
            <div className="section3-bottom">
              <p>{t("homePage.section3.assortment")}</p>
              <p>{t("homePage.section3.assortmentDefinition")}</p>
            </div>
          </m.div>
          <div>
            <div className="section3-upper">
              <img
                src="Assets/images/homePage/section3-2.jpg"
                alt="Proform.az"
              />
              <div className="circular-box2"></div>
            </div>
            <div className="section3-bottom">
              <p>{t("homePage.section3.quality")}</p>
              <p>{t("homePage.section3.qualityDefinition")}</p>
            </div>
          </div>
          <div>
            <div className="section3-upper">
              <img
                src="Assets/images/homePage/section3-3.png"
                alt="Proform.az"
              />
              <div className="circular-box3"></div>
            </div>
            <div className="section3-bottom">
              <p>{t("homePage.section3.punctuality")}</p>
              <p>{t("homePage.section3.punctualityDefinition")}</p>
            </div>
          </div>
        </div>
      </m.section>
    </>
  );
};

export default HomePageSection3;
