import Main from "./HomePageMain";
import "./style.css";

const HomePage = () => {
  return (
    <div className="homepage">
      <Main />
    </div>
  );
};

export default HomePage;
